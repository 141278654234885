<template>
  <div>
    <Header />
    <h1>This is an about page</h1>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderHome.vue"
import Footer from "../components/Footer.vue"

export default {
  components: {
    Header,
    Footer
  }
}
</script>
